import Spinner from '@atlaskit/spinner';
import { yupResolver } from '@hookform/resolvers/yup';
import { GridRowParams } from '@material-ui/data-grid';
import FormDefault from '~/components/FormDefault';
import Loja from '~/components/Loja';
import { BuscaProduto, InputPercent } from '~/components/NovosInputs';
import Search from '~/components/Search';
import ToggleDefault from '~/components/ToggleDefault';
import { LojaContext } from '~/context/loja';
import api from '~/services/api';
import { formatCurrencyAsText, transformAsCurrency } from '~/utils/functions';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { Container } from './styles';
import { SelectProps } from './types';
import { schema } from './validations';

const AjusteMargemProduto: React.FC = () => {
  const {
    register,
    setValue,
    control,
    getValues,
    reset,
    setFocus,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onBlur',
  });
  const { loja } = useContext(LojaContext);
  const [loader, setLoader] = useState<boolean>(false);
  const [isUpdate, setUpdate] = useState<boolean>(false);
  const [clearBuscaProduto, setClearBuscaProduto] = useState<boolean>(false);
  const [showSearch, setShowSearch] = useState(true);
  const [lojaProduto, setLojaProduto] = useState<number>(loja.cod_loja);
  const [disableLoja, setDisableLoja] = useState<boolean>(false);
  const [disableProduto, setDisableProduto] = useState<boolean>(false);
  const [flgPrioritaria, setFlgPrioritaria] = useState<boolean>(false);
  const [initProduto, setInitProduto] = useState<boolean>(false);
  const [codProduto, setCodProduto] = useState<SelectProps>({
    label: '',
    value: undefined,
  });

  useEffect(() => {
    (async () => {
      if (!showSearch) {
        if (!isUpdate) {
          const input = await document.getElementById('cod_produto');
          input?.focus();
        } else {
          setFocus('margem');
        }
      }
    })();
  }, [showSearch]);

  useEffect(() => {
    setValue('cod_loja', loja.cod_loja);
  }, []);

  useEffect(() => {
    setInitProduto(true);
  }, [errors]);

  /**
   * Reseta form para criação de novo registro
   */
  const resetFormData = useCallback(() => {
    setFlgPrioritaria(false);

    setDisableLoja(false);
    setDisableProduto(false);
    reset();
    setValue('cod_loja', loja.cod_loja);
    setLojaProduto(loja.cod_loja);
    setValue('cod_produto', undefined);
    setClearBuscaProduto(true);
    setCodProduto({
      label: '',
      value: undefined,
    });
    setTimeout(() => {
      setClearBuscaProduto(false);
    }, 1000);
  }, [reset, setValue, loja]);

  const handlePrioritaria = useCallback(() => {
    setFlgPrioritaria(!flgPrioritaria);
  }, [flgPrioritaria]);

  const onRowClick = useCallback(
    async (param: GridRowParams) => {
      const { row } = param;
      const {
        cod_produto,
        des_produto,
        val_margem,
        cod_loja,
        flg_prioritaria,
      } = row;

      setLojaProduto(cod_loja);
      setDisableLoja(true);
      setDisableProduto(true);

      setTimeout(() => {
        setValue('cod_produto', {
          label: `${cod_produto} - ${des_produto}`,
          value: cod_produto,
        });

        setValue('margem', formatCurrencyAsText(val_margem));
        setFlgPrioritaria(flg_prioritaria);
      }, 500);

      setCodProduto({
        label: `${cod_produto} - ${des_produto}`,
        value: cod_produto,
      });
      setValue('cod_loja', cod_loja);
      setUpdate(true);
      setLoader(false);
      setShowSearch(false);
    },
    [setValue],
  );

  const onSave = handleSubmit(async () => {
    const { margem, cod_produto, cod_loja } = getValues();
    const margemTransformed = transformAsCurrency(margem);

    if (
      loja.regra_empresa &&
      margemTransformed > 99.99 &&
      loja.regra_empresa.tipo_margem > 0 &&
      loja.regra_empresa.tipo_margem < 4
    ) {
      return toast.warning(
        <>
          <div>Margem de referência informada é maior que 99,99%</div>
          <div style={{ marginTop: '0.3125rem' }}>
            Não permitida para o tipo de margem parametrizado na regra de
            empresa.
          </div>
        </>,
      );
    }

    const { data } = await api.post('/produto-margem', {
      cod_produto: cod_produto.value,
      cod_loja,
      cod_preco: 1,
      val_margem: margemTransformed,
      flg_prioritaria: flgPrioritaria,
    });

    if (data.success) {
      toast.success(data.message);
      resetFormData();
      setShowSearch(true);
    } else {
      toast.error(data.message);
    }

    setLoader(false);
  });

  const handleDelete = async () => {
    toast.error('Função Desabilitada');
  };

  const handleChangeReferencia = useCallback(
    (val: string) => {
      let ValueConverted;
      if (val.includes(',')) {
        const convertedValue = val.replace(',', '');
        const convertedValueString = convertedValue.slice(-2);
        ValueConverted = `${convertedValue.slice(
          0,
          -2,
        )},${convertedValueString}`;
      } else {
        ValueConverted = `00,0${val}`;
      }

      setValue('margem', ValueConverted.replace(',', '.'));
    },
    [setValue],
  );

  const onNew = async () => {
    resetFormData();
    setShowSearch(false);
    setUpdate(false);
    const input = await document.getElementById('cod_produto');
    input?.focus();
  };
  const onCancel = () => {
    setShowSearch(true);
    setUpdate(false);
    resetFormData();
  };

  const getMargemInfo = async () => {
    const { cod_produto, cod_loja } = getValues();
    if (cod_produto) {
      try {
        const { data } = await api.get('/produto-margem/busca-margem-produto', {
          params: {
            cod_produto: cod_produto.value,
            cod_loja,
          },
        });

        if (String(data.data[0].val_margem).includes('.')) {
          setValue('margem', formatCurrencyAsText(data.data[0].val_margem));
        } else {
          setValue('margem', formatCurrencyAsText(data.data[0].val_margem));
        }
        setFlgPrioritaria(data.data[0].flg_prioritaria);
      } catch (e) {
        setLoader(false);
        setValue('margem', '0,00');
      }
    }
  };

  if (loader) {
    return (
      <Container>
        <div className="w-100 h-100 d-flex justify-conten-center align-items-center">
          <Spinner />
        </div>
      </Container>
    );
  }

  return (
    <Container>
      {showSearch && (
        <Search newData={() => onNew()} onRowClick={onRowClick} codTela={94} />
      )}
      {!showSearch && (
        <FormDefault
          codTela={94}
          codigoRegistro={[
            {
              value: codProduto.value,
              des_campo: 'Código',
            },
          ]}
          title="Ajuste de Margem por Produto"
          onSave={onSave}
          onCancel={() => onCancel()}
          isUpdate={isUpdate}
          onNew={() => onNew()}
          onDelete={handleDelete}
          onClearFields={() => resetFormData()}
          onReturnSearch={() => {
            setShowSearch(true);
            setUpdate(false);
            resetFormData();
          }}
          showSwitch={false}
          isDelete={false}
        >
          <Row>
            <Col md={12} sm={12}>
              <Loja
                selectedLoja={lojaProduto}
                IsInvalid={false}
                onChange={(e: number) => {
                  setValue('cod_loja', e);
                  setLojaProduto(e);
                  setValue('cod_produto', {
                    label: 'Selecione...',
                    value: undefined,
                  });

                  setValue('margem', '');
                  setFlgPrioritaria(false);
                }}
                isDisabled={disableLoja}
              />
            </Col>
            <Col md={12} sm={12}>
              <BuscaProduto
                id="cod_produto"
                label="Buscar Produto"
                name="cod_produto"
                register={register}
                control={control}
                placeholder="Selecione..."
                disabled={disableProduto}
                isError={initProduto && !!errors.cod_produto}
                buscaNasLojas={lojaProduto}
                clearCampoBusca={clearBuscaProduto}
                customOptions={{
                  hideBuscarPor: true,
                  buscarPor: ['Produto'],
                  showSelecionarItensContendo: false,
                }}
                getProduct={async (selected) => {
                  setInitProduto(false);
                  setClearBuscaProduto(true);
                  setTimeout(() => {
                    setClearBuscaProduto(false);
                  }, 1000);
                  const selectedAny: any = selected;
                  setValue('cod_produto', {
                    label: `${selectedAny[0].cod_produto} - ${selectedAny[0].des_produto}`,
                    value: selectedAny[0].cod_produto,
                  });
                  getMargemInfo();
                }}
              />
            </Col>
          </Row>
          <div className="row">
            <div className="row">
              <div className="col-sm-12 col-md-2" style={{ minWidth: '180px' }}>
                <InputPercent
                  label="Margem Referência"
                  placeholder="0,00"
                  min={-99999999}
                  max={999999999}
                  onInput={(event: any) =>
                    handleChangeReferencia(event.target.value)
                  }
                  name="margem"
                  register={register}
                  control={control}
                  isError={false}
                />
              </div>
              <div className="col-sm-12 col-md-2">
                <ToggleDefault
                  labelText="Prioritária?"
                  setChecked={flgPrioritaria}
                  onSwitch={handlePrioritaria}
                />
              </div>
            </div>
          </div>
        </FormDefault>
      )}
    </Container>
  );
};

export default AjusteMargemProduto;
